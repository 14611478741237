var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-date-picker", {
                    ref: "picker",
                    attrs: {
                      elevation: "5",
                      "full-width": "",
                      color: "primary",
                      events: _vm.functionEvents,
                      "event-color": (date) => (date[9] % 2 ? "red" : "yellow"),
                    },
                    on: {
                      "click:date": _vm.selectedDateChanged,
                      "click:month": _vm.selectedDateMonthChanged,
                    },
                    model: {
                      value: _vm.selectedDate,
                      callback: function ($$v) {
                        _vm.selectedDate = $$v
                      },
                      expression: "selectedDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "text-center py-0", attrs: { cols: "4" } },
                [
                  _c(
                    "v-chip",
                    {
                      staticClass: "ma-2",
                      attrs: { "x-small": "", color: "green" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("APPOINTMENT_TIME_REGISTERED")) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "text-center py-0", attrs: { cols: "4" } },
                [
                  _c(
                    "v-chip",
                    {
                      staticClass: "ma-2",
                      attrs: { "x-small": "", color: "blue" },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("APPOINTMENT_TIME_AVAILABLE")) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "text-center py-0", attrs: { cols: "4" } },
                [
                  _c(
                    "v-chip",
                    {
                      staticClass: "ma-2",
                      attrs: { "x-small": "", color: "gray" },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("APPOINTMENT_TIME_PASSED")) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", [_c("h6", [_vm._v(_vm._s(_vm.$t("APPOINTMENTS")))])]),
              _c(
                "v-col",
                { attrs: { align: "left" } },
                [
                  _c(
                    "v-dialog",
                    {
                      attrs: {
                        fullscreen: "",
                        "hide-overlay": "",
                        transition: "dialog-bottom-transition",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "mx-2",
                                      attrs: {
                                        depressed: "",
                                        icon: "",
                                        color: "white",
                                        plain: "",
                                        disabled: !_vm.isBeforeToday(),
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "primary", large: "" } },
                                    [_vm._v(" mdi-plus-box")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.appointmentDialog,
                        callback: function ($$v) {
                          _vm.appointmentDialog = $$v
                        },
                        expression: "appointmentDialog",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-toolbar",
                            { attrs: { dark: "", color: "primary" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "", dark: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.appointmentDialog = false
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { color: "white" } }, [
                                    _vm._v("mdi-close"),
                                  ]),
                                ],
                                1
                              ),
                              _c("v-toolbar-title", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("ADD_APPOINTMENT")) +
                                    " - " +
                                    _vm._s(_vm.selectedDateInJalali()) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-container",
                            [
                              _c(
                                "v-row",
                                { staticClass: "py-3" },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c("date-picker", {
                                        attrs: {
                                          type: "time",
                                          placeholder: "ساعت شروع",
                                        },
                                        on: {
                                          "click:minute": function ($event) {
                                            return _vm.$refs.fromMenu.save(
                                              _vm.timesheetDetail.fromTime
                                            )
                                          },
                                          input: _vm.updateAppointmentTimeTo,
                                        },
                                        model: {
                                          value: _vm.timesheetDetail.fromTime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.timesheetDetail,
                                              "fromTime",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "timesheetDetail.fromTime",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c("date-picker", {
                                        attrs: {
                                          type: "time",
                                          placeholder: "ساعت خاتمه",
                                        },
                                        on: {
                                          "click:minute": function ($event) {
                                            return _vm.$refs.fromMenu.save(
                                              _vm.timesheetDetail.toTime
                                            )
                                          },
                                          input: _vm.updateAppointmentTimeTo,
                                        },
                                        model: {
                                          value: _vm.timesheetDetail.toTime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.timesheetDetail,
                                              "toTime",
                                              $$v
                                            )
                                          },
                                          expression: "timesheetDetail.toTime",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0 ma-0",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c("v-text-field", {
                                        directives: [
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: _vm.currencyMask,
                                            expression: "currencyMask",
                                          },
                                        ],
                                        staticClass: "text-center",
                                        attrs: {
                                          readonly: "",
                                          outlined: "",
                                          type: "text",
                                          "hide-details": "auto",
                                          suffix: _vm.$t("RIAL"),
                                          label: _vm.$t("APPOINTMENT_PRICE"),
                                        },
                                        model: {
                                          value: _vm.doctor.consultantAmount,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.doctor,
                                              "consultantAmount",
                                              $$v
                                            )
                                          },
                                          expression: "doctor.consultantAmount",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0 ma-0",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticStyle: {
                                            "text-decoration": "none",
                                          },
                                          attrs: {
                                            to: { name: "DoctorFinancial" },
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "orange--text text--darken-4",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "CHANGE_CONSULTANT_AMOUNT_HERE"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-3 ma-0",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c("v-textarea", {
                                        staticClass: "text-start",
                                        attrs: {
                                          outlined: "",
                                          type: "text",
                                          "hide-details": "auto",
                                          label: _vm.$t("DESCRIPTION-DOCTOR"),
                                          maxlength: "500",
                                          "auto-grow": "",
                                        },
                                        model: {
                                          value:
                                            _vm.timesheetDetail.description,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.timesheetDetail,
                                              "description",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "timesheetDetail.description",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("p", [
                                        _vm._v(
                                          _vm._s(_vm.$t("APPOINTMENT_TYPE"))
                                        ),
                                      ]),
                                      _c(
                                        "v-radio-group",
                                        {
                                          model: {
                                            value:
                                              _vm.timesheetDetail.sessionType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.timesheetDetail,
                                                "sessionType",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "timesheetDetail.sessionType",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: { value: "ONLINE" },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "div",
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getUserIcon(
                                                                  "ONLINE"
                                                                )
                                                              )
                                                          ),
                                                        ]),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t("ONLINE")
                                                            ) +
                                                            " "
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ]),
                                          }),
                                          _c("v-radio", {
                                            attrs: { value: "ON_SITE" },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "div",
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getUserIcon(
                                                                  "ON_SITE"
                                                                )
                                                              )
                                                          ),
                                                        ]),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t("ON_SITE")
                                                            ) +
                                                            " "
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ]),
                                          }),
                                          _c("v-radio", {
                                            attrs: { value: "ALL" },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "div",
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getUserIcon(
                                                                  "ALL"
                                                                )
                                                              )
                                                          ),
                                                        ]),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t("BOTH")
                                                            ) +
                                                            " "
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _vm.timesheetDetail.fromTime === null ||
                                      _vm.timesheetDetail.toTime === null ||
                                      _vm.timesheetDetail.sessionType ===
                                        null ||
                                      _vm.doctor.consultantAmount === ""
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  block: "",
                                                                  depressed: "",
                                                                  color:
                                                                    "grey lighten-2",
                                                                },
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t("SAVE")
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                3873856820
                                              ),
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.doctor
                                                        .consultantAmount === ""
                                                        ? _vm.$t(
                                                            "Please enter the consultant amount"
                                                          )
                                                        : _vm.$t(
                                                            "Please fill out the required information"
                                                          )
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    block: "",
                                                    color: "primary",
                                                  },
                                                  on: {
                                                    click: _vm.saveAppointment,
                                                  },
                                                },
                                                "v-btn",
                                                _vm.attrs,
                                                false
                                              ),
                                              _vm.on
                                            ),
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("SAVE")) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _vm.getTimeSheetsOfDate().length < 1
                ? _c(
                    "v-col",
                    { attrs: { align: "center", cols: "12" } },
                    [
                      true
                        ? _c("H4", [
                            _vm._v(
                              _vm._s(_vm.$t("NO_APPOINTMENTS_REGISTERED"))
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.getTimeSheetsOfDate().length > 0
                ? _c(
                    "v-col",
                    {
                      staticClass: "py-0",
                      staticStyle: { height: "0" },
                      attrs: { cols: "12" },
                    },
                    _vm._l(
                      _vm.getTimeSheetsOfDate(),
                      function (timesheet, index) {
                        return _c(
                          "v-list",
                          { key: index },
                          [
                            _c(
                              "v-list-item",
                              [
                                _c(
                                  "v-list-item-title",
                                  {
                                    staticClass:
                                      "ms-2 d-flex justify-content-between",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("moment")(
                                              _vm.selectedDate,
                                              "dddd , jD jMMMM jYYYY"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(timesheet.fromTime) +
                                          " - " +
                                          _vm._s(timesheet.toTime) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item",
                              [
                                _c(
                                  "v-list-item-icon",
                                  [
                                    timesheet.userAppointment
                                      ? _c(
                                          "v-avatar",
                                          { attrs: { size: "50" } },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: timesheet.userAppointment
                                                  .clientUserPic,
                                                alt: "Appointment-logo",
                                              },
                                            }),
                                          ]
                                        )
                                      : _c("b-icon", {
                                          staticClass:
                                            "bg-light pa-4 rounded-circle",
                                          attrs: {
                                            icon: "person",
                                            width: "50",
                                            height: "50",
                                            variant: "info",
                                          },
                                        }),
                                  ],
                                  1
                                ),
                                _c("v-list-item-title", [
                                  _c("span", { staticClass: "d-block" }, [
                                    timesheet.userAppointment
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                timesheet.userAppointment
                                                  .clientFullName
                                              ) +
                                              " "
                                          ),
                                        ])
                                      : _c(
                                          "span",
                                          { staticClass: "grey--text" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "NO_APPOINTMENTS_RESERVED"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                  ]),
                                  _c("span", { staticClass: "f10" }, [
                                    timesheet.userAppointment
                                      ? _c(
                                          "span",
                                          {
                                            class: timesheet.userAppointment
                                              .paid
                                              ? "text-green"
                                              : "text-danger",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  timesheet.userAppointment.paid
                                                    ? _vm.$t(
                                                        "USER-APPOINTMENT-PAID"
                                                      )
                                                    : _vm.$t(
                                                        "USER-APPOINTMENT-NOT-PAID"
                                                      )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" | "),
                                    _c(
                                      "span",
                                      {
                                        class:
                                          timesheet.userAppointment === null
                                            ? "text-primary"
                                            : "text-green",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                timesheet.userAppointment ===
                                                  null
                                                  ? timesheet.sessionType
                                                  : timesheet.userAppointment
                                                      .sessionType
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item",
                              [
                                _c("v-list-item-title", [
                                  _c("span", { staticClass: "pe-3" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("DESCRIPTION")) +
                                        " : " +
                                        _vm._s(
                                          timesheet.description
                                            ? timesheet.description
                                            : "-"
                                        )
                                    ),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "ms-2 rounded-lg text-muted",
                                    attrs: {
                                      small: "",
                                      outlined: "",
                                      color: "primary",
                                      disabled:
                                        timesheet.userAppointment === null,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openInfoBottomSheet(
                                          timesheet,
                                          index
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("USER-APPOINTMENT-MORE-INFO")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-dialog",
                                  {
                                    attrs: { "max-width": "500" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "ms-2 rounded-lg text-muted",
                                                      attrs: {
                                                        small: "",
                                                        outlined: "",
                                                        color: "muted",
                                                        elevation: "0",
                                                      },
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "USER_APPOINTMENT_CANCEL"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.cancelAppointment,
                                      callback: function ($$v) {
                                        _vm.cancelAppointment = $$v
                                      },
                                      expression: "cancelAppointment",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-card",
                                      { staticClass: "text-center pa-4" },
                                      [
                                        _c(
                                          "v-card-title",
                                          {
                                            staticClass:
                                              "f16 font-weight-bold d-block",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "CANCEL_RESERVED_APPOINTMENT"
                                                )
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                        _c("v-divider"),
                                        _c(
                                          "v-card-text",
                                          {
                                            staticClass:
                                              "f14 p-2 font-weight-light",
                                          },
                                          [
                                            _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "Please contact the support team for cancellation"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "v-card-actions",
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "mb-2",
                                                attrs: {
                                                  color: "warning",
                                                  block: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.cancelAppointment = false
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("I Understand")
                                                  ) + " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    ),
                    1
                  )
                : _vm._e(),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-dialog",
                    {
                      attrs: { persistent: "", "max-width": "290" },
                      model: {
                        value: _vm.deleteDialog,
                        callback: function ($$v) {
                          _vm.deleteDialog = $$v
                        },
                        expression: "deleteDialog",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c("v-card-title", { staticClass: "text-h5" }, [
                            _vm._v(" " + _vm._s(_vm.$t("ARE_YOU_SURE")) + " "),
                          ]),
                          _c("v-card-text", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("ARE_YOU_SURE_TO_DELETE_TIME", {
                                    from: _vm.getTimeSheetDetailById(
                                      _vm.timesheetDeleteId
                                    )
                                      ? _vm.getTimeSheetDetailById(
                                          _vm.timesheetDeleteId
                                        ).fromTime
                                      : "00:00:00",
                                    to: _vm.getTimeSheetDetailById(
                                      _vm.timesheetDeleteId
                                    )
                                      ? _vm.getTimeSheetDetailById(
                                          _vm.timesheetDeleteId
                                        ).toTime
                                      : "00:00:00",
                                  })
                                ) +
                                " "
                            ),
                          ]),
                          _c(
                            "v-card-actions",
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "red darken-1", text: "" },
                                  on: {
                                    click: _vm.commitTimesheetDetailDelete,
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("Delete")) + " ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.deleteDialog = false
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "vue-bottom-sheet",
        {
          ref: "infoBottomSheet",
          class: _vm.isBottomSheetOpen ? "height-30rem" : "height-0rem",
        },
        [
          _c(
            "section",
            {
              staticClass:
                "d-flex flex-column align-items-center justify-content-center",
            },
            [
              _c("v-list-item-title", [_vm._v(" جزییات نوبت ")]),
              _c(
                "v-row",
                { staticClass: "w-100" },
                [
                  _c("v-col", { staticClass: "d-flex", attrs: { cols: "8" } }, [
                    _c(
                      "div",
                      { staticClass: "ms-2" },
                      [
                        _vm.selectedInfo.userAppointment &&
                        _vm.selectedInfo.userAppointment.clientUserPic
                          ? _c("v-avatar", { attrs: { size: "50" } }, [
                              _c("img", {
                                attrs: {
                                  src: _vm.selectedInfo.userAppointment
                                    .clientUserPic,
                                  alt: "Appointment-logo",
                                },
                              }),
                            ])
                          : _c("b-icon", {
                              staticClass: "bg-light pa-4 rounded-circle",
                              attrs: {
                                icon: "person",
                                width: "50",
                                height: "50",
                                variant: "info",
                              },
                            }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-center align-items-center text-center f12",
                      },
                      [
                        _vm.selectedInfo.userAppointment
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.selectedInfo.userAppointment
                                      .clientFullName
                                  ) +
                                  " "
                              ),
                            ])
                          : _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("NO_APPOINTMENTS_RESERVED")) +
                                  " "
                              ),
                            ]),
                      ]
                    ),
                  ]),
                  _vm.selectedInfo.userAppointment
                    ? _c(
                        "v-col",
                        {
                          staticClass:
                            "d-flex justify-content-center align-items-center",
                          attrs: { cols: "4" },
                        },
                        [
                          _c(
                            "v-chip",
                            {
                              staticClass: "f10",
                              class: _vm.selectedInfo.userAppointment.paid
                                ? "text-green"
                                : "text-danger",
                              attrs: { small: "", outlined: "" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.selectedInfo.userAppointment.paid
                                      ? _vm.$t("USER-APPOINTMENT-PAID")
                                      : _vm.$t("USER-APPOINTMENT-NOT-PAID")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.selectedInfo.userAppointment
                ? _c(
                    "v-row",
                    { staticClass: "w-100 mb-2", attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              "hide-details": "",
                              readonly: "",
                              label: "ساعت جلسه",
                              value:
                                _vm.currentDateTime +
                                " | " +
                                _vm.selectedInfo.fromTime +
                                ` - ` +
                                _vm.selectedInfo.toTime,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectedInfo.userAppointment
                ? _c(
                    "v-row",
                    { staticClass: "w-100 mb-2", attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              "hide-details": "",
                              label: "نوع جلسه",
                              readonly: "",
                              value: _vm.$t(
                                _vm.selectedInfo.userAppointment === null
                                  ? _vm.selectedInfo.sessionType
                                  : _vm.selectedInfo.userAppointment.sessionType
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectedInfo.userAppointment
                ? _c(
                    "v-row",
                    { staticClass: "w-100", attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-textarea", {
                            attrs: {
                              outlined: "",
                              "hide-details": "",
                              label: "توضیحات مراجع",
                              readonly: "",
                              value:
                                _vm.selectedInfo.userAppointment.description,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                { staticClass: "w-100 mb-2" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-textarea", {
                        attrs: {
                          outlined: "",
                          "hide-details": "",
                          label: "توضیحات متخصص",
                        },
                        model: {
                          value: _vm.updatedDescription,
                          callback: function ($$v) {
                            _vm.updatedDescription = $$v
                          },
                          expression: "updatedDescription",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-auto mb-2",
                          attrs: { block: "", color: "success", rounded: "" },
                          on: { click: _vm.updateDoctorDescription },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("submit")) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.selectedInfo.userAppointment
                ? _c(
                    "v-row",
                    { staticClass: "w-100", attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-auto mb-2",
                              attrs: {
                                block: "",
                                color: "primary",
                                rounded: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.closeInfoBottomSheet()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("Close")) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }