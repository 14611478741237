<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-date-picker
              ref="picker"
              elevation="5"
              full-width
              color="primary"
              v-model="selectedDate"
              :events="functionEvents"
              @click:date="selectedDateChanged"
              @click:month="selectedDateMonthChanged"
              :event-color="(date) => (date[9] % 2 ? 'red' : 'yellow')"
          ></v-date-picker>
        </v-col>
        <v-col cols="4" class="text-center py-0">
          <v-chip class="ma-2" x-small color="green">
            {{ $t("APPOINTMENT_TIME_REGISTERED") }}
          </v-chip>
        </v-col>
        <v-col cols="4" class="text-center py-0">
          <v-chip class="ma-2" x-small color="blue">
            {{ $t("APPOINTMENT_TIME_AVAILABLE") }}
          </v-chip>
        </v-col>
        <v-col cols="4" class="text-center py-0">
          <v-chip class="ma-2" x-small color="gray">
            {{ $t("APPOINTMENT_TIME_PASSED") }}
          </v-chip>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h6>{{ $t("APPOINTMENTS") }}</h6>
        </v-col>
        <v-col align="left">
          <v-dialog
              v-model="appointmentDialog"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  class="mx-2"
                  depressed
                  icon
                  color="white"
                  plain
                  :disabled="!isBeforeToday()"
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon color="primary" large> mdi-plus-box</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-toolbar dark color="primary">
                <v-btn icon dark @click="appointmentDialog = false">
                  <v-icon color="white">mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>
                  {{ $t("ADD_APPOINTMENT") }} - {{ selectedDateInJalali() }}
                </v-toolbar-title>
              </v-toolbar>
              <v-container>
                <v-row class="py-3">
                  <v-col cols="6">
                    <!--                    <v-menu-->
                    <!--                        ref="fromMenu"-->
                    <!--                        v-model="appointmentTimePickerFrom"-->
                    <!--                        :close-on-content-click="false"-->
                    <!--                        :nudge-right="40"-->
                    <!--                        :return-value.sync="timesheetDetail.fromTime"-->
                    <!--                        transition="scale-transition"-->
                    <!--                        offset-y-->
                    <!--                        max-width="290px"-->
                    <!--                        min-width="290px"-->
                    <!--                    >-->
                    <!--                      <template v-slot:activator="{ on, attrs }">-->
                    <!--                        <v-text-field-->
                    <!--                            outlined-->
                    <!--                            readonly-->
                    <!--                            hide-details="auto"-->
                    <!--                            class="text-center"-->
                    <!--                            v-model="timesheetDetail.fromTime"-->
                    <!--                            :label="$t('APPOINTMENT_START_TIME')"-->
                    <!--                            v-bind="attrs"-->
                    <!--                            @click="computeAllowedTimes()"-->
                    <!--                            v-on="on"-->
                    <!--                        ></v-text-field>-->
                    <!--                      </template>-->
                    <date-picker v-model="timesheetDetail.fromTime" type="time"
                                 @click:minute="
                                   $refs.fromMenu.save(timesheetDetail.fromTime)"
                                 placeholder="ساعت شروع"
                                 @input="updateAppointmentTimeTo"/>
                    <!--                      <v-time-picker-->
                    <!--                          v-if="appointmentTimePickerFrom"-->
                    <!--                          v-model="timesheetDetail.fromTime"-->
                    <!--                          full-width-->
                    <!--                          format="24hr"-->
                    <!--                          :allowed-hours="allowedHours"-->
                    <!--                          :allowed-minutes="allowedMinutes"-->
                    <!--                                                @click:minute="-->
                    <!--                                                $refs.fromMenu.save(timesheetDetail.fromTime)-->
                    <!--                        "-->
                    <!--                          @input="updateAppointmentTimeTo"-->
                    <!--                      ></v-time-picker>-->
                    <!--                    </v-menu>-->
                  </v-col>
                  <v-col cols="6">
                    <date-picker v-model="timesheetDetail.toTime" type="time"
                                 @click:minute="
                                   $refs.fromMenu.save(timesheetDetail.toTime)"
                                 placeholder="ساعت خاتمه"
                                 @input="updateAppointmentTimeTo"/>
                    <!--                    <v-menu-->
                    <!--                        ref="toMenu"-->
                    <!--                        v-model="appointmentTimePickerTo"-->
                    <!--                        :close-on-content-click="false"-->
                    <!--                        :nudge-right="40"-->
                    <!--                        :return-value.sync="timesheetDetail.toTime"-->
                    <!--                        transition="scale-transition"-->
                    <!--                        offset-y-->
                    <!--                        max-width="290px"-->
                    <!--                        min-width="290px"-->
                    <!--                    >-->
                    <!--                      <template v-slot:activator="{ on, attrs }">-->
                    <!--                        <v-text-field-->
                    <!--                            readonly-->
                    <!--                            outlined-->
                    <!--                            hide-details="auto"-->
                    <!--                            class="text-center"-->
                    <!--                            v-model="timesheetDetail.toTime"-->
                    <!--                            :label="$t('APPOINTMENT_END_TIME')"-->
                    <!--                            v-bind="attrs"-->
                    <!--                            @click="computeAllowedTimes()"-->
                    <!--                            v-on="on"-->
                    <!--                        ></v-text-field>-->
                    <!--                      </template>-->
                    <!--                      <v-time-picker-->
                    <!--                          v-if="appointmentTimePickerTo"-->
                    <!--                          v-model="timesheetDetail.toTime"-->
                    <!--                          full-width-->
                    <!--                          format="24hr"-->
                    <!--                          :allowed-hours="allowedHours"-->
                    <!--                          :allowed-minutes="allowedMinutes"-->
                    <!--                          @click:minute="-->
                    <!--                          $refs.toMenu.save(timesheetDetail.toTime)-->
                    <!--                        "-->
                    <!--                      ></v-time-picker>-->
                    <!--                    </v-menu>-->
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="py-0 ma-0">
                    <v-text-field
                        readonly
                        outlined
                        type="text"
                        hide-details="auto"
                        class="text-center"
                        v-mask="currencyMask"
                        :suffix="$t('RIAL')"
                        :label="$t('APPOINTMENT_PRICE')"
                        v-model="doctor.consultantAmount"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0 ma-0">
                    <router-link
                        :to="{ name: 'DoctorFinancial' }"
                        style="text-decoration: none"
                    >
                      <span class="orange--text text--darken-4">
                        {{ $t("CHANGE_CONSULTANT_AMOUNT_HERE") }}
                      </span>
                    </router-link>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pt-3 ma-0">
                    <v-textarea
                        outlined
                        type="text"
                        hide-details="auto"
                        class="text-start"
                        :label="$t('DESCRIPTION-DOCTOR')"
                        v-model="timesheetDetail.description"
                        maxlength="500"
                        auto-grow
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <p>{{ $t("APPOINTMENT_TYPE") }}</p>
                    <v-radio-group v-model="timesheetDetail.sessionType">
                      <v-radio value="ONLINE">
                        <template v-slot:label>
                          <div>
                            <v-icon> {{ getUserIcon("ONLINE") }}</v-icon>
                            {{ $t("ONLINE") }}
                          </div>
                        </template>
                      </v-radio>
                      <v-radio value="ON_SITE">
                        <template v-slot:label>
                          <div>
                            <v-icon> {{ getUserIcon("ON_SITE") }}</v-icon>
                            {{ $t("ON_SITE") }}
                          </div>
                        </template>
                      </v-radio>
                      <v-radio value="ALL">
                        <template v-slot:label>
                          <div>
                            <v-icon> {{ getUserIcon("ALL") }}</v-icon>
                            {{ $t("BOTH") }}
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-tooltip
                        bottom
                        v-if="
                        timesheetDetail.fromTime === null ||
                        timesheetDetail.toTime === null ||
                        timesheetDetail.sessionType === null ||
                        doctor.consultantAmount === ''
                        "
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            block depressed
                            color="grey lighten-2"
                            v-bind="attrs"
                            v-on="on"
                        >
                          {{ $t("SAVE") }}
                        </v-btn>
                      </template>
                      <span>
                        {{
                          doctor.consultantAmount === '' ?
                              $t("Please enter the consultant amount") :
                              $t('Please fill out the required information')
                        }}
                      </span>
                    </v-tooltip>
                    <v-btn
                        block
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="saveAppointment"
                        v-else
                    >
                      {{ $t("SAVE") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row>

        <v-col align="center" cols="12" v-if="getTimeSheetsOfDate().length < 1">
          <H4 v-if="true">{{ $t("NO_APPOINTMENTS_REGISTERED") }}</H4>
        </v-col>

        <v-col cols="12" v-if="getTimeSheetsOfDate().length > 0" class="py-0" style="height: 0">
          <v-list v-for="(timesheet, index) in getTimeSheetsOfDate()"
                  :key="index">
            <v-list-item>
              <v-list-item-title class="ms-2 d-flex justify-content-between">
                <span>
                  {{ selectedDate | moment("dddd , jD jMMMM jYYYY") }}
                </span>
                <span>
                  {{ timesheet.fromTime }} - {{ timesheet.toTime }}
                </span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-avatar size="50" v-if="timesheet.userAppointment ">
                  <img :src="timesheet.userAppointment.clientUserPic" alt="Appointment-logo"/>
                </v-avatar>
                <b-icon icon="person" width="50" height="50" class="bg-light pa-4 rounded-circle" variant="info"
                        v-else></b-icon>
              </v-list-item-icon>
              <v-list-item-title>
                <span class="d-block">
                  <span v-if="timesheet.userAppointment">
                    {{ timesheet.userAppointment.clientFullName }}
                  </span>
                  <span v-else class="grey--text">
                    {{ $t("NO_APPOINTMENTS_RESERVED") }}
                  </span>
                </span>
                <span
                    class="f10">
                  <span v-if="timesheet.userAppointment"
                        :class="timesheet.userAppointment.paid ? 'text-green' : 'text-danger'">
                    {{
                      timesheet.userAppointment.paid ? $t("USER-APPOINTMENT-PAID") : $t("USER-APPOINTMENT-NOT-PAID")

                    }}
                  </span>
                  |
                    <span :class="timesheet.userAppointment === null ? 'text-primary' : 'text-green'">
                      {{
                        $t(timesheet.userAppointment === null ? timesheet.sessionType : timesheet.userAppointment.sessionType)
                      }}
                    </span>

                  </span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <span class="pe-3">{{ $t("DESCRIPTION") }} : {{timesheet.description ? timesheet.description : '-'}}</span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-btn class="ms-2 rounded-lg text-muted" small outlined color="primary"
                     @click="openInfoBottomSheet(timesheet, index)" :disabled="timesheet.userAppointment === null">
                {{
                  $t("USER-APPOINTMENT-MORE-INFO")
                }}
              </v-btn>
              <v-dialog v-model="cancelAppointment" max-width="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="ms-2 rounded-lg text-muted" small outlined color="muted"
                         elevation="0" v-on="on" v-bind="attrs">
                    {{
                      $t("USER_APPOINTMENT_CANCEL")
                    }}
                  </v-btn>
                </template>

                <v-card class="text-center pa-4">
                  <v-card-title class="f16 font-weight-bold d-block">{{
                      $t("CANCEL_RESERVED_APPOINTMENT")
                    }}
                  </v-card-title>
                  <v-divider/>
                  <v-card-text class="f14 p-2 font-weight-light">
                    <p>
                      {{
                        $t(
                            "Please contact the support team for cancellation"
                        )
                      }}
                    </p>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn
                        @click="cancelAppointment = false"
                        color="warning"
                        block
                        class="mb-2"
                    >{{ $t("I Understand") }}
                    </v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col cols="auto">
          <v-dialog v-model="deleteDialog" persistent max-width="290">
            <v-card>
              <v-card-title class="text-h5">
                {{ $t("ARE_YOU_SURE") }}
              </v-card-title>
              <v-card-text>
                {{
                  $t("ARE_YOU_SURE_TO_DELETE_TIME", {
                    from: getTimeSheetDetailById(timesheetDeleteId)
                        ? getTimeSheetDetailById(timesheetDeleteId).fromTime
                        : "00:00:00",
                    to: getTimeSheetDetailById(timesheetDeleteId)
                        ? getTimeSheetDetailById(timesheetDeleteId).toTime
                        : "00:00:00",
                  })
                }}
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="red darken-1"
                    text
                    @click="commitTimesheetDetailDelete"
                >
                  {{ $t("Delete") }}
                </v-btn>
                <v-btn text @click="deleteDialog = false">
                  {{ $t("Cancel") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>

    <vue-bottom-sheet ref="infoBottomSheet" :class="isBottomSheetOpen ? 'height-30rem' : 'height-0rem'">
      <section class="d-flex flex-column align-items-center justify-content-center">
        <v-list-item-title>
          جزییات نوبت
        </v-list-item-title>
        <v-row class="w-100">
          <v-col class="d-flex" cols="8">
            <div class="ms-2">
              <v-avatar size="50"
                        v-if="selectedInfo.userAppointment && selectedInfo.userAppointment.clientUserPic">
                <img :src="selectedInfo.userAppointment.clientUserPic" alt="Appointment-logo"/>
              </v-avatar>
              <b-icon icon="person" width="50" height="50" class="bg-light pa-4 rounded-circle" variant="info"
                      v-else></b-icon>
            </div>
            <div class="d-flex justify-content-center align-items-center text-center f12">
              <span v-if="selectedInfo.userAppointment">
                {{ selectedInfo.userAppointment.clientFullName }}
                </span>
              <span v-else>
                  {{ $t("NO_APPOINTMENTS_RESERVED") }}
                </span>
            </div>
          </v-col>
          <v-col cols="4" class="d-flex justify-content-center align-items-center"
                 v-if="selectedInfo.userAppointment">
            <v-chip small class="f10" outlined
                    :class="selectedInfo.userAppointment.paid ? 'text-green' : 'text-danger'">
              {{
                selectedInfo.userAppointment.paid ? $t("USER-APPOINTMENT-PAID") : $t("USER-APPOINTMENT-NOT-PAID")

              }}
            </v-chip>
          </v-col>
        </v-row>
        <v-row dense class="w-100 mb-2" v-if="selectedInfo.userAppointment">
          <v-col>
            <v-text-field outlined hide-details readonly
                          label="ساعت جلسه"
                          :value="currentDateTime + ' | ' + selectedInfo.fromTime + ` - ` + selectedInfo.toTime">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row dense class="w-100 mb-2" v-if="selectedInfo.userAppointment">
          <v-col>
            <v-text-field outlined hide-details label="نوع جلسه" readonly
                          :value="$t(selectedInfo.userAppointment === null ?
                           selectedInfo.sessionType : selectedInfo.userAppointment.sessionType)">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row dense class="w-100" v-if="selectedInfo.userAppointment">
          <v-col>
            <v-textarea outlined hide-details label="توضیحات مراجع" readonly
                        :value="selectedInfo.userAppointment.description">
            </v-textarea>
          </v-col>
        </v-row>
        <v-row class="w-100 mb-2">
          <v-col cols="12">
            <v-textarea
                outlined
                hide-details
                v-model="updatedDescription"
                label="توضیحات متخصص"
            ></v-textarea>
          </v-col>
          <v-col cols="12">
            <v-btn
                block
                color="success"
                @click="updateDoctorDescription"
                class="mt-auto mb-2"
                rounded
            >
              {{ $t("submit") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row dense class="w-100" v-if="selectedInfo.userAppointment">
          <v-col>
            <v-btn
                block
                color="primary"
                @click="closeInfoBottomSheet()"
                class="mt-auto mb-2"
                rounded
            >{{ $t("Close") }}
            </v-btn>
          </v-col>
        </v-row>
      </section>
    </vue-bottom-sheet>

  </div>
</template>
<script>
import apiService from "@/http/ApiService";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";

moment.updateLocale("fa", fa);
moment.loadPersian();

import createNumberMask from "text-mask-addons/dist/createNumberMask";
import {all} from "core-js/internals/document-all";
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'

const currencyMask = createNumberMask({
  prefix: "",
  allowDecimal: false,
  includeThousandsSeparator: true,
  allowNegative: false,
});

Vue.use(VuePersianDatetimePicker, {
  name: 'custom-date-picker',
  props: {
    editable: true,
    color: '#20b1ea',
  }
})

export default {
  components: {
    datePicker: VuePersianDatetimePicker
  },
  computed: {
    all() {
      return all
    },
    currentDateTime() {
      let date = moment(this.selectedDate).format("dddd jD jMMMM")
      return date
    }
  },
  data() {
    return {
      updatedDescription: '',
      isBottomSheetOpen: false,
      timePlaceholder: '',
      currencyMask,
      cancelAppointment: false,
      selectedDate: null,
      yearKeeper: null,
      monthKeeper: null,
      page: 0,
      pageSize: 500,
      timesheets: [],
      appointmentDialog: false,
      appointmentTimePickerFrom: false,
      appointmentTimePickerTo: false,
      drProfile: null,
      deleteDialog: false,
      timesheetDeleteId: null,
      allowedHours: [],
      allowedMinutes: [],
      timesheetDetail: {
        id: null,
        fromTime: null,
        toTime: null,
        sessionType: null,
        description: "",
      },
      doctor: {
        consultantAmount: 0.0,
      },

      selectedInfo: {},
      selectedInfoPostition: -1,
    };
  },
  beforeMount() {
    this.$store.commit("update_backActive", true);
    this.$store.commit("update_helloActive", false);
    this.$store.commit("update_threeDotsActive", false);
    this.$store.commit("update_notificationActive", true);
    this.$store.commit("update_toolbarTitleActive", true);
    this.selectedDate = moment().format("YYYY-MM-DD");
    this.yearKeeper = moment().year();
    this.monthKeeper = moment().month();
  },
  mounted() {
    const prevBtn = this.$refs.picker.$el.querySelector(
        '.v-btn[aria-label="' +
        this.$vuetify.lang.t("$vuetify.datePicker.prevMonthAriaLabel") +
        '"]'
    );
    prevBtn.addEventListener("click", () => {
      this.selectedDateMonthChanged(
          this.$refs.picker.tableYear +
          "-" +
          ("0" + (this.$refs.picker.tableMonth + 1)).slice(-2)
      );
    });

    const nextBtn = this.$refs.picker.$el.querySelector(
        '.v-btn[aria-label="' +
        this.$vuetify.lang.t("$vuetify.datePicker.nextMonthAriaLabel") +
        '"]'
    );
    nextBtn.addEventListener("click", () => {
      this.selectedDateMonthChanged(
          this.$refs.picker.tableYear +
          "-" +
          ("0" + (this.$refs.picker.tableMonth + 1)).slice(-2)
      );
    });
    apiService
        .getDoctorInfoByMyMobile()
        .then((response) => {
          this.doctor = response.data;
        })
        .catch((err) => {
          this.$swal({
            icon: "warning",
            text: err ? err.response.data.detail : this.$t("Error"),
            confirmButtonText: this.$t('OK'),
          });
        });
    this.updateEvents();
  },
  methods: {
    saveAppointment() {
      if (this.doctor.consultantAmount !== null && this.doctor.consultantAmount !== '') {
        const found = this.timesheets.filter(
            (t) => this.selectedDate === t.valueDate
        );
        if (this.timesheetDetail.id !== null) {
          const d = this.getTimeSheetDetailById(this.timesheetDetail.id);
          d.fromTime = this.timesheetDetail.fromTime;
          d.toTime = this.timesheetDetail.toTime;
          d.sessionType = this.timesheetDetail.sessionType;
          d.description = this.timesheetDetail.description;
          apiService
              .updateDoctorTimesheetDetail(d, found[0].id)
              .then((response) => {
                const data = response.data;
                this.deleteDetailFromTimesheet(found[0], d.id);
                found[0].timeSheetDetails.push(data);
              })
              .catch((err) => {
                this.$swal({
                  icon: "warning",
                  text: err ? err.response.data.detail : this.$t("Error"),
                  confirmButtonText: this.$t('OK'),
                });
              });
        } else if (found.length > 0) {
          apiService
              .saveDoctorTimesheetDetail(this.timesheetDetail, found[0].id)
              .then((response) => {
                const data = response.data;
                found[0].timeSheetDetails.push(data);
              })
              .catch((err) => {
                this.$swal({
                  icon: "warning",
                  text: err ? err.response.data.detail : this.$t("Error"),
                  confirmButtonText: this.$t('OK'),
                });
              });
        } else {
          const t = {
            valueDate: this.selectedDate,
            timeSheetDetails: [this.timesheetDetail],
          };
          apiService
              .saveDoctorTimesheet(t)
              .then((response) => {
                const data = response.data;
                this.timesheets.push(data);
              })
              .catch((err) => {
                this.$swal({
                  icon: "warning",
                  text: err ? err.response.data.detail : this.$t("Error"),
                  confirmButtonText: this.$t('OK'),
                });
              });
        }
        this.resetTimesheetDetail();
        this.appointmentDialog = false;
      } else {
        this.$swal({
          icon: "warning",
          text: this.$t("Please enter the consultant amount"),
          confirmButtonText: this.$t('OK'),
        });
        this.appointmentDialog = true
      }
    },
    computeAllowedTimes() {
      const currentDate = new Date();
      if (this.selectedDate == moment(currentDate).format("YYYY-MM-DD")) {
        const currentHour = currentDate.getHours();
        const currentMinute = currentDate.getMinutes();

        const isTimeValid = (hour, minute) => {
          return hour > currentHour || (hour === currentHour && minute >= currentMinute);
        };

        this.allowedHours = Array.from({length: 24}, (_, index) => index).filter(hour =>
            isTimeValid(hour, 0)
        );
        this.allowedMinutes = Array.from({length: 60}, (_, index) => index)
            .filter(minute => isTimeValid(currentHour, minute))
      } else {
        // Allow all hours for future dates
        this.allowedHours = Array.from({length: 24}, (_, index) => index);
        this.allowedMinutes = Array.from({length: 60}, (_, index) => index);
      }
    },
    selectedDateChanged() {
      const d = moment(this.selectedDate);
      if (d.year() !== this.yearKeeper || d.month() !== this.monthKeeper) {
        this.updateEvents(this.selectedDate);
        this.yearKeeper = d.year();
        this.monthKeeper = d.month();
      }
    },
    getTimeSheetsOfDate() {
      const found = this.timesheets.filter(
          (t) => this.selectedDate === t.valueDate
      );
      if (found.length > 0 && found[0].timeSheetDetails.length > 0) {
        return found[0].timeSheetDetails.slice().sort(function (a, b) {
          return a.fromTime.localeCompare(b.fromTime);
        });
      }
      return [];
    },
    updateEvents(date) {
      apiService
          .getDoctorTimesheets(
              this.getFirstDayOfSelectedMonth(date),
              this.getLastDayOfSelectedMonth(date),
              this.page,
              this.pageSize
          )
          .then((response) => {
            const data = response.data;
            this.timesheets = data.content;
            this.selectedInfo = this.timesheets.length ? this.timesheets[0].timeSheetDetails[0] : {}
          })
          .catch((err) => {
            this.$swal({
              icon: "warning",
              text: err ? err.response.data.detail : this.$t("Error"),
              confirmButtonText: this.$t('OK'),
            });
          });
    },

    getFirstDayOfSelectedMonth(date) {
      return moment(date).startOf("month").format("YYYY-MM-DD");
    },
    getLastDayOfSelectedMonth(date) {
      return moment(date).endOf("month").format("YYYY-MM-DD");
    },

    functionEvents(date) {
      const found = this.timesheets.filter((t) => date === t.valueDate);
      if (found.length > 0) {
        const colors = [];
        const timesheet = found[0];
        const hasUnregistered = timesheet.timeSheetDetails.some(
            (td) => td.userAppointment === null
        );
        const hasRegistered = timesheet.timeSheetDetails.some(
            (td) => td.userAppointment !== null
        );
        const passed = moment(found[0].valueDate, "YYYY-MM-DD").isBefore(
            moment()
        );
        if (hasRegistered) {
          colors.push("green");
        }
        if (hasUnregistered && passed) {
          colors.push("grey");
        } else if (hasUnregistered && !passed) {
          colors.push("blue");
        }
        return colors;
      }
      return false;
    },
    getUserIcon(sessionType) {
      if (sessionType === "ONLINE") {
        return "mdi-video-account";
      } else if (sessionType === "ALL") {
        return "mdi-account-heart";
      } else {
        return "mdi-account-supervisor";
      }
    },
    editTimesheetDetail(id) {
      const d = this.getTimeSheetDetailById(id);
      this.timesheetDetail = {
        id: d.id,
        fromTime: d.fromTime.substring(0, 5),
        toTime: d.toTime.substring(0, 5),
        sessionType: d.sessionType,
      };
      this.appointmentDialog = true;
    },
    deleteTimesheetDetail(id) {
      this.timesheetDeleteId = id;
      this.deleteDialog = true;
    },
    commitTimesheetDetailDelete() {
      const deleteID = this.timesheetDeleteId;
      apiService
          .deleteDoctorTimesheetDetail(deleteID)
          .then(() => {
            const found = this.timesheets.filter(
                (t) => this.selectedDate === t.valueDate
            );
            if (found.length > 0) {
              this.deleteDetailFromTimesheet(found[0], deleteID);
            }
          })
          .catch((err) => {
            this.$swal({
              icon: "warning",
              text: err ? err.response.data.detail : this.$t("Error"),
              confirmButtonText: this.$t('OK'),
            });
          });
      this.timesheetDeleteId = null;
      this.deleteDialog = false;
    },
    deleteDetailFromTimesheet(timesheet, detailId) {
      timesheet.timeSheetDetails = timesheet.timeSheetDetails.filter(
          (d) => d.id !== detailId
      );
    },
    selectedDateInJalali() {
      return moment(this.selectedDate).format("jYYYY/jM/jD");
    },
    isBeforeToday() {
      return moment(this.selectedDate).isAfter(
          moment().clone().subtract(1, "days").startOf("day")
      );
    },
    getTimeSheetDetailById(id) {
      const found = this.timesheets.filter(
          (t) => this.selectedDate === t.valueDate
      );
      if (found.length > 0) {
        const timesheet = found[0];
        return timesheet.timeSheetDetails.filter((tsd) => id === tsd.id)[0];
      }
    },
    updateAppointmentTimeTo(fromTime) {
      if (this.timesheetDetail.toTime === null) {
        this.timesheetDetail.toTime =
            ("0" + (parseInt(fromTime.slice(0, 2)) + 1)).slice(-2) +
            fromTime.slice(2);
      }
    },
    resetTimesheetDetail() {
      this.timesheetDetail = {
        id: null,
        fromTime: null,
        toTime: null,
        sessionType: null,
      };
    },
    idDisabledAppointmentEditDelete(user) {
      return user !== null || !this.isBeforeToday();
    },
    selectedDateMonthChanged(date) {
      console.info(this.selectedDate);
      console.info(date + this.selectedDate.substring(7));
      this.updateEvents(date + this.selectedDate.substring(7));
    },

    //   Info Bottom Sheet
    openInfoBottomSheet(info, index) {
      this.selectedInfo = info;
      this.updatedDescription = info.description;
      this.selectedInfoPostition = index;
      this.isBottomSheetOpen = true
      this.$refs.infoBottomSheet.open();
    },
    closeInfoBottomSheet() {
      this.isBottomSheetOpen = false
      this.$refs.infoBottomSheet.close();
      this.selectedInfo = {};
      this.selectedInfoPostition = -1;
    },
    updateDoctorDescription() {
      if (this.updatedDescription) {
        const data = {
          fromTime: this.selectedInfo.fromTime,
          toTime: this.selectedInfo.toTime,
          sessionType: this.selectedInfo.sessionType,
          description: this.updatedDescription,
        }
        // Call API to update the timesheet detail
        apiService
            .updateDoctorTimesheetDetail(data, this.selectedInfo.id)
            .then(() => {
              this.isBottomSheetOpen = false;
              this.$refs.infoBottomSheet.close();
              this.updateEvents()
            })
            .catch((err) => {
              this.$swal({
                icon: "warning",
                text: err ? err.response.data.detail : this.$t("Error"),
                confirmButtonText: this.$t('OK'),
              });
            });
      }
    }
  },
  watch: {
    appointmentDialog(v) {
      if (!v) {
        this.resetTimesheetDetail()
      }
    }
  }
};
</script>
<style>
.vpd-time .vpd-time-h .vpd-counter-item, .vpd-time .vpd-time-m .vpd-counter-item {
  top: -30px !important;
}

.height-30rem {
  height: 30rem !important;
}

.height-0rem {
  height: 0 !important;
}
</style>